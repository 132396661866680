import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { CustomProvider } from 'rsuite';

import StyleOverrides from './components/UI/StyleOverrides';
import {
  AccountDetails,
  ConditionalTrade,
  ScheduledTradeRouter,
  StableCoinBot,
  Login,
  Logout,
  ErrorPage,
  Register,
} from './routes';
import Root from './routes/components/Root';
import ProtectedRoute from './routes/components/ProtectedRoute';

import useTheme from './hooks/theme';
import { Provider as SessionProvider } from './hooks/session';

import 'rsuite/dist/rsuite.min.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Root />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      { path: '/', ...ScheduledTradeRouter },
      { path: '/schedule', ...ScheduledTradeRouter },
      { path: '/conditional', element: <ConditionalTrade /> },
      { path: '/stablecoin', element: <StableCoinBot /> },
      { path: '/account_details', element: <AccountDetails /> },
    ]
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'register',
    element: <Register />,
  },
  {
    path: 'logout',
    element: <Logout />,
  }
]);

export default function App() {
  const theme = useTheme();

  return (
    <CustomProvider theme={theme}>
      <StyleOverrides>
        <SessionProvider>
          <RouterProvider router={router} />
        </SessionProvider>
      </StyleOverrides>
    </CustomProvider>
  );
}
