import ListTrades from './ListTrades';
import NewTrade from './NewTrade';

const BASE_PATH = '/schedule';

const router = {
  children: [
    { path: BASE_PATH + '/', element: <ListTrades />},
    { path: BASE_PATH + '/new', element: <NewTrade /> },
  ]
};

export default router;
