import { logout } from '../hooks/session';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:8080';

class Api {
  #accessToken;

  configure({ accessToken }) {
    this.#accessToken = accessToken;
  }

  authenticate(username, password) {
    return this.#request('/api/auth/token', {
      method: 'POST',
      body: { username, password }
    });
  }

  getAccounts() {
    return this.#request('/api/v2/accounts', {
      method: 'GET',
    });
  }

  createDelayedOrder(tradeRequest) {
    return this.#request('/api/trade/open-delayed-orders', {
      method: 'POST',
      body: tradeRequest
    });
  }

  cancelDelayedOrder(taskId) {
    return this.#request(`/api/trade/cancel?taskId=${taskId}`, {
      method: 'DELETE',
    });
  }

  listDelayedOrders() {
    return this.#request('/api/trade/tasks', {
      method: 'GET',
    });
  }

  listSymbols() {
    return this.#request('/api/trade/fields', {
      method: 'GET'
    }).then(response => response.symbols);
  }

  stableCoinBotEnable(exchange, enabled) {
    return this.#request(`/api/v2/stablecoin/${exchange}`, {
      method: 'PUT',
      body: { enabled }
    });
  }

  stableCoinBotStatuses() {
    return this.#request('/api/v2/stablecoin', {
      method: 'GET',
    });
  }

  spotWalletBalances() {
    return this.#request('/api/v2/accounts/binance/spot/wallet_balances', {
      method: 'GET',
    });
  }

  spotOpenOrders() {
    return this.#request('/api/v2/accounts/binance/spot/open_orders', {
      method: 'GET',
    });
  }

  spotDepositHistory() {
    return this.#request('/api/v2/accounts/binance/spot/deposit_history', {
      method: 'GET',
    });
  }

  spotWithdrawHistory() {
    return this.#request('/api/v2/accounts/binance/spot/withdraw_history', {
      method: 'GET',
    });
  }

  futuresAccountBalances() {
    return this.#request('/api/v2/accounts/binance/futures/account_balances', {
      method: 'GET',
    });
  }

  futuresOpenOrders() {
    return this.#request('/api/v2/accounts/binance/futures/open_orders', {
      method: 'GET',
    });
  }

  futuresPositions() {
    return this.#request('/api/v2/accounts/binance/futures/positions', {
      method: 'GET',
    });
  }

  #request(path, { method, body }) {
    let url = BASE_URL + path;

    const headers = {
      'Content-Type': 'application/json'
    };

    if (this.#accessToken) {
      headers.Authorization = `Bearer ${this.#accessToken}`;
    }

    return fetch(
      url,
      {
        method,
        headers,
        body: JSON.stringify(body),
      }
    ).then(response =>
      response.text().then(responseText => {
        let responseJSON = null;

        try {
          responseJSON = JSON.parse(responseText);
        } catch (e) { }

        if (response.ok) {
          return responseJSON;
        } else {
          // TODO: find the better place to handle this
          if (response.status === 401) logout();
          throw responseJSON;
        }
      })
    );
  }
}

export default window._api_client = new Api();
