import { Link, Navigate, useNavigate } from 'react-router-dom';
import {
  useToaster,
  Col,
  Container,
  Divider,
  FlexboxGrid,
  Panel,
  Notification
} from 'rsuite';

import Login from '../../components/Auth/Login';
import useSession, { login } from '../../hooks/session';

export default function LoginView() {
  const navigate = useNavigate();
  const session = useSession();
  const toaster = useToaster();

  if (session) {
    return <Navigate to="/" replace />;
  }

  const authenticate = async ({ username, password }) =>
    login({ username, password })
      .then(() => navigate('/'))
      .catch((error) => {
        toaster.push(
          <Notification type="error" header="Error">
            {error?.error || error?.message || "Неизвестная ошибка"}
          </Notification>
        );
      });

  return (
    <Container>
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item as={Col} colspan={20} style={{maxWidth: 400}}>
          <Panel header={<h3>Login</h3>} bordered style={{ marginTop: 100 }}>
            <Login onLogin={authenticate} />

            <Divider />

            Don't have an account? <strong><Link to="/register">Register</Link></strong>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Container>
  );
}
