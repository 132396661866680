import { useState } from 'react';
import {
  Button,
  ButtonToolbar,
  Form,
  Schema
} from 'rsuite';
import { TextField } from '../UI/Form/TextField';
import { VALIDATION_MESSAGES } from '../../utils/schemaValidation';

const { StringType } = Schema.Types;

const model = Schema.Model({
  username: StringType().isRequired(VALIDATION_MESSAGES.required),
  password: StringType().isRequired(VALIDATION_MESSAGES.required),
});

export default function Login({ onLogin }) {
  const [formValue, setFormValue] = useState({
    username: '',
    password: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitHandler = (checkStatus, _event) => {
    if (!checkStatus) return;

    setIsSubmitting(true);
    return onLogin(formValue).finally(() => setIsSubmitting(false));
  }

  return (
    <Form
      fluid
      onSubmit={submitHandler}
      onChange={setFormValue}
      formValue={formValue}
      model={model}
    >
      <TextField name="username" label="Username" />
      <TextField name="password" label="Password" type="password" autoComplete="off" />

      <Form.Group>
        <ButtonToolbar>
          <Button appearance="primary" type="submit" loading={isSubmitting} block>Sign in</Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  );
}
