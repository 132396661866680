import { useState } from 'react';
import { Form } from 'rsuite';
import SymbolPicker from '../ScheduledTrade/SymbolPicker';
import TradingViewWidget from '../TradingViewWidget';

export default function ConditionalTradeForm() {
  const [formValue, setFormValue] = useState({
    symbol: '',
  });

  return (
    <Form
      fluid
      formValue={formValue}
      onChange={setFormValue}
    >
      <Form.Group controlId="symbol">
        <Form.ControlLabel>
          Валютная пара
          <Form.HelpText tooltip>Фьючерс, присутствующий на Binance</Form.HelpText>
        </Form.ControlLabel>

        <Form.Control name="symbol" accepter={SymbolPicker} />
      </Form.Group>

      {formValue.symbol && (
        <TradingViewWidget symbol={formValue.symbol} />
      )}

    </Form>
  );
}
