import { Schema } from 'rsuite';

const { StringType, NumberType, DateType } = Schema.Types;

const model = Schema.Model({
  symbol: StringType().isRequired('Укажите торговую пару.'),
  quantity: NumberType().isRequired('Укажите количество.'),
  leverage: NumberType().isRequired('Укажите плечо.'),
  startDate: DateType().isRequired('Укажите дату начала попыток размещение ордера.'),
  finishDate: DateType()
    .addRule((value, data) => {
      return new Date(data.startDate) < new Date(value);
    }, 'Дата окончания попыток разместить ордер должна быть позже даты начала'),

  stopLossOrderPlacementDate:
    DateType()
      .addRule((value, data) => data.stopLossOrderEnabled ? !!value : true, 'Укажите дату размещения стоп-лосс ордера.')
      .addRule(
        (value, data) => (data.stopLossOrderEnabled && new Date(data.startDate) < new Date(value)), 'Дата размещения стоп-лосс ордера должна быть позже даты начала'),
  stopLossOrderRemovalDate:
    DateType()
      .addRule(
        (value, data) => (data.stopLossOrderEnabled && new Date(data.stopLossOrderPlacementDate) < new Date(value)),
        'Дата снятия стоп-лосс ордера должна быть позже даты размещения'),

  takeProfitOrderPlacementDate:
    DateType()
      .addRule(
        (value, data) => {
          return (data.takeProfitOrderEnabled && new Date(data.startDate) < new Date(value));
        },
        'Дата размещения тейк-профит ордера должна быть позже даты начала'),

  takeProfitOrderRemovalDate:
    DateType()
      .addRule(
        (value, data) => (data.takeProfitOrderEnabled && new Date(data.takeProfitOrderPlacementDate) < new Date(value)),
        'Дата снятия тейк-профит ордера должна быть позже даты размещения'),

  repeatFrequency: NumberType().isRequired('Укажите частоту повторения.'),
  maxDeviation: NumberType().isRequired('Укажите максимальное отклонение.'),
  maxSpread: NumberType().isRequired('Укажите максимальный спред.'),
});

export { model };
