import { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { device } from '../utils/screenSizeHelpers';
import useTheme from '../hooks/theme';

let tvScriptLoadingPromise;

export default function TradingViewWidget({
  symbol,
}) {
  const onLoadScriptRef = useRef();
  const theme = useTheme();

  useLayoutEffect(
    () => {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById('tradingview_720dd') && 'TradingView' in window) {
          new window.TradingView.widget({
            autosize: true,
            symbol: `BINANCE:${symbol}`,
            timezone: "Etc/UTC",
            theme,
            style: "1",
            locale: "en",
            toolbar_bg: "#f1f3f6",
            enable_publishing: false,
            withdateranges: true,
            hide_side_toolbar: false,
            allow_symbol_change: false,
            save_image: false,
            range: "1M",
            container_id: "tradingview_720dd"
          });
        }
      }
    },
    [symbol, theme]
  );

  const WidgetRoot = styled.div`
    @media ${device.mobileS} {
      #tradingview_720dd {
        height: 494px;
      }
    }

    @media ${device.laptop} {
      #tradingview_720dd {
        height: 610px;
      }
    }
  `;

  return (
    <WidgetRoot className="tradingview-widget-container">
      <div id="tradingview_720dd" />
      <div className="tradingview-widget-copyright">
        <a href={`https://www.tradingview.com/symbols/BINANCE-${symbol}/`} rel="noreferrer" target="_blank"><span className="blue-text">{symbol}</span></a> by TradingView
      </div>
    </WidgetRoot>
  );
}
