import { DatePicker } from 'rsuite';

const subOneDay = (date) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - 1);
  return newDate;
};

const DatePickerImproved = (props) => (
  <DatePicker {...props}
    shouldDisableDate={date => date < subOneDay(new Date())}
    hideHours={(hour, date) => {
      const today = new Date();
      return date.getDate() === today.getDate() &&
        hour < today.getHours();
    }}
    hideMinutes={(minute, date) => {
      const today = new Date();
      return date.getDate() === today.getDate() &&
        date.getHours() === today.getHours() &&
        minute < today.getMinutes();
    }}
  />
);

export {
  DatePickerImproved as DatePicker
};
