import { useCallback, useState, useRef } from 'react';
import { Button, ButtonToolbar, Stack, Form, InputNumber, Toggle, IconButton, } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import CloseIcon from '@rsuite/icons/Close';
import FormControlLabel from 'rsuite/esm/FormControlLabel';
import { v4 as uuidv4 } from 'uuid';
import SymbolPicker from './SymbolPicker';
import { DatePicker } from '../UI/Form/DatePicker';
import { model } from './TradeFormSchema';

const defaultFormValue = {
  symbol: '',
  quantity: '',
  leverage: '1',

  startDate: null,
  finishDate: null,

  stopLossOrderEnabled: false,
  stopLossOrderPlacementDate: null,
  stopLossOrderValue: '',
  stopLossOrderRemovalDate: null,

  takeProfitOrderEnabled: false,
  takeProfitOrderPlacementDate: null,
  takeProfitOrderValue: '',
  takeProfitOrderRemovalDate: null,

  repeatFrequency: '',

  maxDeviation: '',
  maxSpread: '',

  stops: [],
};

export default function OrderForm({ onSubmit, onFormSave, onFormRestore }) {
  const formRef = useRef();
  const [formValue, setFormValue] = useState(defaultFormValue);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback((event) => {
    if (!formRef.current.check()) return;

    setIsSubmitting(true);

    const tradeDirection =
      event.target.value === 'long'
        ? 'BUY'
        : 'SELL';

    return onSubmit({ ...formValue, tradeDirection })
      .then(() => { setFormValue(defaultFormValue); })
      .finally(() => { setIsSubmitting(false); });
  }, [formValue, onSubmit]);

  const handleFormSave = useCallback(() => {
    if (formRef.current.check()) {
      onFormSave(formValue);
    }
  }, [onFormSave, formValue]);

  console.log(JSON.stringify(formValue));

  const handleFormRestore = useCallback(() => {
    const scheduledTradingFormValues = onFormRestore();

    if (!scheduledTradingFormValues) return setFormValue(defaultFormValue);

    Object.keys(scheduledTradingFormValues).forEach((key) => {
      if (key.endsWith('Date') && scheduledTradingFormValues[key] !== null) {
        scheduledTradingFormValues[key] = new Date(scheduledTradingFormValues[key]);
      }
    });

    setFormValue(scheduledTradingFormValues);
  }, [onFormRestore, setFormValue]);

  console.log(JSON.stringify(formErrors));

  return (
    <Form
      fluid
      model={model}
      ref={formRef}
      formValue={formValue}
      onChange={setFormValue}
      onCheck={setFormErrors}
    >
      <Form.Group controlId="symbol">
        <Form.ControlLabel>
          Валютная пара
          <Form.HelpText tooltip>Фьючерс, присутствующий на Binance</Form.HelpText>
        </Form.ControlLabel>

        <Form.Control name="symbol" accepter={SymbolPicker} />
      </Form.Group>

      <Form.Group controlId="quantity">
        <Form.ControlLabel>
          Объем USD
          <Form.HelpText tooltip>
            Объем позиции без учета плеча <br />
            ("Объем USD" x "Кредитное Плечо" = "Размер позиции")
          </Form.HelpText>
        </Form.ControlLabel>

        <Form.Control name="quantity" accepter={InputNumber} min={0} step={0.01} />
      </Form.Group>

      <Form.Group controlId="leverage">
        <Form.ControlLabel>Кредитное плечо</Form.ControlLabel>
        <Form.Control name="leverage" accepter={InputNumber} min={1} />
      </Form.Group>

      <Form.Group controlId="startDate">
        <Form.ControlLabel>Дата размещения ордера</Form.ControlLabel>
        <Form.Control name="startDate" accepter={DatePicker} format="yyyy-MM-dd HH:mm:ss" block />
      </Form.Group>

      <Form.Group controlId="finishDate">
        <Form.ControlLabel>Дата завершения попыток совершения сделки</Form.ControlLabel>
        <Form.Control name="finishDate" accepter={DatePicker} format="yyyy-MM-dd HH:mm:ss" block />
      </Form.Group>

      <Form.Group controlId="takeProfitOrderEnabled">
        <Form.ControlLabel>Использовать тейк-профит</Form.ControlLabel>
        <Form.Control name="takeProfitOrderEnabled" accepter={Toggle} />
      </Form.Group>

      {formValue.takeProfitOrderEnabled && (
        <>
          <Form.Group controlId="takeProfitOrderValue">
            <Form.ControlLabel>Тейк-профит, %</Form.ControlLabel>
            <Form.Control name="takeProfitOrderValue" accepter={InputNumber} min={0} step={0.01} />
          </Form.Group>

          <Form.Group controlId="takeProfitOrderPlacementDate">
            <Form.ControlLabel>Дата размещения тейк-профит ордера</Form.ControlLabel>
            <Form.Control name="takeProfitOrderPlacementDate" accepter={DatePicker} format="yyyy-MM-dd HH:mm:ss" block />
          </Form.Group>

          <Form.Group controlId="takeProfitOrderRemovalDate">
            <Form.ControlLabel>Дата удаления тейк-профит ордера</Form.ControlLabel>
            <Form.Control name="takeProfitOrderRemovalDate" accepter={DatePicker} format="yyyy-MM-dd HH:mm:ss" block />
          </Form.Group>
        </>
      )}

      <Form.Group controlId="stopLossOrderEnabled">
        <Form.ControlLabel>Использовать стоп-лосс</Form.ControlLabel>
        <Form.Control name="stopLossOrderEnabled" accepter={Toggle} />
      </Form.Group>

      {formValue.stopLossOrderEnabled && (
        <>
          <Form.Group controlId="stopLossOrderValue">
            <Form.ControlLabel>Стоп-лосс, %</Form.ControlLabel>
            <Form.Control name="stopLossOrderValue" accepter={InputNumber} min={0} step={0.01} />
          </Form.Group>

          <Form.Group controlId="stopLossOrderPlacementDate">
            <Form.ControlLabel>Дата размещения стоп-лосс ордера</Form.ControlLabel>
            <Form.Control name="stopLossOrderPlacementDate" accepter={DatePicker} format="yyyy-MM-dd HH:mm:ss" block />
          </Form.Group>

          <Form.Group controlId="stopLossOrderRemovalDate">
            <Form.ControlLabel>Дата удаления стоп-лосс ордера</Form.ControlLabel>
            <Form.Control name="stopLossOrderRemovalDate" accepter={DatePicker} format="yyyy-MM-dd HH:mm:ss" block />
          </Form.Group>
        </>
      )}

      <Form.Group controlId="maxDeviation">
        <Form.ControlLabel>
          Максимальное отклонение, %
          <Form.HelpText tooltip>
            Разница между ценой актива при первой попытке войти в рынок и предельной ценой
          </Form.HelpText>
        </Form.ControlLabel>

        <Form.Control name="maxDeviation" accepter={InputNumber} min={0} step={0.01} />
      </Form.Group>

      <Form.Group controlId="maxSpread">
        <Form.ControlLabel>
          Максимальный спред, %
          <Form.HelpText tooltip>
            Разница между лучшей ценой "покупки" и "продажи". Если фактическое значение будет больше - сделка не будет размещена
          </Form.HelpText>
        </Form.ControlLabel>

        <Form.Control name="maxSpread" accepter={InputNumber} min={0} step={0.01} />
      </Form.Group>

      <Form.Group controlId="repeatFrequency">
        <Form.ControlLabel>
          Частота совершения сделок, мс
          <Form.HelpText tooltip>Если не удалось разместить ордер - повторная попытка будет выполнена через N мс</Form.HelpText>
        </Form.ControlLabel>

        <Form.Control name="repeatFrequency" accepter={InputNumber} min={0} step={0.01} />
      </Form.Group>

      <Form.Group>
        <StopList stops={formValue.stops} setStops={stops => setFormValue({ ...formValue, stops })} />
      </Form.Group>

      <Form.Group>
        <ButtonToolbar>
          <Button appearance="default" value="save" onClick={handleFormSave}>Сохранить форму</Button>
          <Button appearance="default" value="restore" onClick={handleFormRestore}>Восстановить форму</Button>
        </ButtonToolbar>
      </Form.Group>

      <ButtonToolbar>
        <Button color="green" appearance="primary" value="long" onClick={handleSubmit} loading={isSubmitting}>Купить/Long</Button>
        <Button color="red" appearance="primary" value="short" onClick={handleSubmit} loading={isSubmitting}>Продать/Short</Button>
      </ButtonToolbar>
    </Form>
  );
}

function StopList({ stops, setStops }) {
  const addStop = () => {
    const stop = { id: uuidv4(), expectedPricePercentage: '', stopPricePercentage: '' };
    return setStops([...stops, stop]);
  };

  const removeStop = (event) => {
    const { id } = event.target.closest('div[data-id]').dataset;
    return setStops(stops.filter(stop => stop.id !== id));
  };

  const changeHandler = (value, event) => {
    const { id } = event.target.closest('div[data-id]').dataset;
    const { name } = event.target.parentElement.dataset;

    const index = stops.findIndex(s => s.id === id);

    if (index >= 0) {
      const stop = {
        ...stops[index],
        [name]: value,
      };

      return setStops([
        ...stops.slice(0, index),
        stop,
        ...stops.slice(index + 1)
      ]);
    }
  };

  return (
    <>
      {stops.map((stop) => (
        <Form.Group key={stop.id} data-id={stop.id}>

          <Stack spacing={6} alignItems="flex-end" justifyContent="center">
            <IconButton icon={<CloseIcon />} onClick={removeStop} />

            <Stack.Item grow={1}>
              <Form.Group>
                <FormControlLabel>Ожидаемая цена, %</FormControlLabel>
                <InputNumber data-name="expectedPricePercentage" step={0.01} value={stop.expectedPricePercentage} onChange={changeHandler} />
              </Form.Group>
            </Stack.Item>

            <Stack.Item grow={1}>
              <Form.Group>
                <FormControlLabel>Процент стопа, %</FormControlLabel>
                <InputNumber data-name="stopPricePercentage" step={0.01} value={stop.stopPricePercentage} onChange={changeHandler} />
              </Form.Group>
            </Stack.Item>

          </Stack>
        </Form.Group>
      ))}

      <IconButton icon={<PlusIcon />} onClick={addStop}>Добавить стоп</IconButton>
    </>
  );
}
