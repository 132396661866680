import { useEffect, useState, useCallback } from 'react';
import { SelectPicker, Loader } from 'rsuite';
import Api from '../../lib/api';

export default function SymbolPicker(props) {
  const [items, setItems] = useState([]);

  const updateData = useCallback(async () => {
    if (items.length === 0) {
      const symbols = await Api.listSymbols();

      setItems(
        symbols.map(({ symbol }) => ({ label: symbol, value: symbol }))
      );
    };
  }, [items]);

  const renderMenu = menu => {
    if (items.length === 0) {
      return <Loader content="Loading..." backdrop />;
    }
    return menu;
  };

  useEffect(() => {
    updateData();
  }, [updateData]);

  return (
    <SelectPicker
      block
      data={items}
      onOpen={updateData}
      renderMenu={renderMenu}
      onClean={() => props.onChange('')}
      placeholder="Выбрать"
      {...props}
    />
  );
}
