
import { useState, useEffect, useCallback } from 'react';
import { useToaster, Notification, Modal, Button } from 'rsuite';
import ListTrades from '../../components/ScheduledTrade/ListTrades';
import Api from '../../lib/api';

const FETCH_ORDERS_INTERVAL = 5000;

const ConfirmationDialog = ({ open, handleAgree, handleClose }) => (
  <Modal backdrop="static" role="alertdialog" open={open} onClose={handleClose} size="xs" style={{ marginTop: '8vh' }}>
    <Modal.Body>
      Отменить трейд?
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={handleAgree} appearance="primary">
        Да
      </Button>
      <Button onClick={handleClose} appearance="subtle">
        Нет
      </Button>
    </Modal.Footer>
  </Modal>
);


export default function ListTradesContainer() {
  const [orders, setOrders] = useState([]);
  const [orderDeletionId, setOrderDeletionId] = useState();

  const toaster = useToaster();

  const fetchOrders = useCallback(
    () =>
      Api.listDelayedOrders()
        .then(response => Array.isArray(response) ? setOrders(response) : setOrders([]))
        .catch(error => {
          toaster.push(<Notification type="error" header="Error">{error?.error || error?.message || "Неизвестная ошибка"}</Notification>);
        }),
    []
  );

  const cancelOrder = useCallback(() => {
    Api.cancelDelayedOrder(orderDeletionId)
      .then(() => setOrderDeletionId(undefined))
      .then(fetchOrders)
      .catch(error => {
        toaster.push(<Notification type="error" header="Error">{error?.error || error?.message || "Неизвестная ошибка"}</Notification>);
      })
    },
    [fetchOrders, orderDeletionId]
  );

  useEffect(() => {
    fetchOrders();
    const timer = setInterval(fetchOrders, FETCH_ORDERS_INTERVAL);
    return () => clearInterval(timer);
  }, [fetchOrders]);

  return (
    <div>
      <ConfirmationDialog open={!!orderDeletionId} handleAgree={cancelOrder} handleClose={() => setOrderDeletionId(undefined)} />
      <ListTrades orders={orders} onDelete={setOrderDeletionId} />
    </div>
  )
}
