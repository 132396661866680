export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export function timeDifference(startDate, endDate) {
  const timeDiff = endDate.getTime() - startDate.getTime();

  const days = Math.floor(timeDiff / DAY);
  const hours = Math.floor((timeDiff % DAY) / HOUR);
  const minutes = Math.floor((timeDiff % HOUR) / MINUTE);

  let timeString = `${minutes} minutes`;

  if (hours > 0) timeString = `${hours} hours ` + timeString;
  if (days > 0) timeString = `${days} days ` + timeString;

  return timeString;
}
