import { Table, Toggle } from 'rsuite';
import { useToaster, Notification, DOMHelper } from 'rsuite';
import Api from '../lib/api';
import { useCallback, useEffect, useState } from 'react';
import { timeDifference, SECOND } from '../utils/time';

const CHECK_BOT_STATUS_INTERVAL = 60 * SECOND;

export default function StableCoinBot() {
  const toaster = useToaster();
  const [isLoading, setIsLoading] = useState(true);
  const [botStatuses, setBotStatuses] = useState([]);

  const { getHeight } = DOMHelper;

  const fetchBotStatuses = useCallback(
    (abortRequest) => {
      setIsLoading(true);

      Api.stableCoinBotStatuses()
        .then((statuses) => {
          if (!abortRequest) {
            setBotStatuses(statuses);
          };
        })
        .catch(error => {
          console.error(error);
          toaster.push(
            <Notification type="error" header="Error">{error?.error || "Неизвестная ошибка"}</Notification>
          );
        })
        .finally(() => setIsLoading(false));
    }, []
  );

  useEffect(() => {
    let abortRequest = false;

    fetchBotStatuses(abortRequest);

    const checkBotStatusInterval = setInterval(
      () => fetchBotStatuses(abortRequest),
      CHECK_BOT_STATUS_INTERVAL
    );

    return () => {
      abortRequest = true;
      clearInterval(checkBotStatusInterval);
    };
  }, [fetchBotStatuses]);

  const onToggle = (exchange, checked) => {
    setIsLoading(true);

    Api.stableCoinBotEnable(exchange, checked)
      .then(() => fetchBotStatuses())
      .catch(error => {
        console.error(error);
        toaster.push(
          <Notification type="error" header="Error">{error?.error || "Неизвестная ошибка"}</Notification>
        );
      })
      .finally(() => setIsLoading(false));
  };

  const { Column, HeaderCell, Cell } = Table;

  return (
    <Table
      height={Math.max(getHeight(window) - 250, 400)}
      data={botStatuses}
      loading={isLoading}
    >
      <Column flexGrow={1} minWidth={80}>
        <HeaderCell>Exchange</HeaderCell>
        <Cell dataKey="exchange" style={{textTransform: 'uppercase'}}/>
      </Column>

      <Column flexGrow={2} minWidth={200}>
        <HeaderCell>Uptime</HeaderCell>
        <Cell>
          {rowData => rowData.statistics
            ? timeDifference(new Date(rowData.statistics.startDate), new Date())
            : null}
        </Cell>
      </Column>

      <Column flexGrow={1} minWidth={120}>
        <HeaderCell>Trade balance</HeaderCell>
        <Cell>
          {rowData => rowData.statistics
            ? `${(rowData.statistics.tradeBalance || 0).toFixed(3)}$`
            : null}
        </Cell>
      </Column>

      <Column flexGrow={1} minWidth={120}>
        <HeaderCell>Money in orders</HeaderCell>
        <Cell>
          {rowData => rowData.statistics
            ? `${(rowData.statistics.balanceInOrders || 0).toFixed(3)}$`
            : null}
        </Cell>
      </Column>

      <Column flexGrow={1} minWidth={100}>
        <HeaderCell>Profit</HeaderCell>
        <Cell>
          {rowData => rowData.statistics ? `${rowData.statistics.profit.toFixed(3)}$` : null}
        </Cell>
      </Column>

      <Column flexGrow={1} minWidth={100}>
        <HeaderCell>APR</HeaderCell>
        <Cell>
          {rowData =>  rowData.statistics ? `~${(rowData.statistics.apr * 100).toFixed(3)}%` : null}
        </Cell>
      </Column>

      <Column flexGrow={1} minWidth={120}>
        <HeaderCell>Successful Trades</HeaderCell>
        <Cell>
          {rowData => rowData.statistics ? rowData.statistics.successfulTrades : null}
        </Cell>
      </Column>

      <Column flexGrow={1} minWidth={100}>
        <HeaderCell>Total Trades</HeaderCell>
        <Cell>
          {rowData => rowData.statistics ? rowData.statistics.totalTrades : null}
        </Cell>
      </Column>

      <Column flexGrow={1} minWidth={100}>
        <HeaderCell>Buy Attempts</HeaderCell>
        <Cell>
          {rowData => rowData.statistics ? rowData.statistics.totalAttempts.buy : null}
        </Cell>
      </Column>

      <Column flexGrow={1} minWidth={100}>
        <HeaderCell>Sell Attempts</HeaderCell>
        <Cell>
          {rowData => rowData.statistics ? rowData.statistics.totalAttempts.sell : null}
        </Cell>
      </Column>

      <Column flexGrow={1} minWidth={100}>
        <HeaderCell>Status</HeaderCell>
        <Cell style={{ padding: '6px' }}>
          {rowData => (
            <Toggle
              size="lg"
              checked={rowData.enabled}
              checkedChildren="On"
              unCheckedChildren="Off"
              loading={isLoading}
              onChange={checked => onToggle(rowData.exchange, checked)}
            />
          )}
        </Cell>
      </Column>
    </Table>
  );
}
