import { useEffect, useState, useCallback } from 'react';
import { Table, useToaster, Notification } from 'rsuite';
import Api from '../lib/api';

export default function AccountDetails() {
  const toaster = useToaster();

  const [spotWalletBalances, setSpotWalletBalances] = useState([]);
  const [spotDepositHistory, setSpotDepositHistory] = useState([]);
  const [spotWithdrawHistory, setSpotWithdrawHistory] = useState([]);
  const [spotOpenOrders, setSpotOpenOrders] = useState([]);
  const [futuresAccountBalances, setFuturesAccountBalances] = useState([]);
  const [futuresOpenOrders, setFuturesOpenOrders] = useState([]);
  const [futuresPositions, setFuturesPositions] = useState([]);

  const handleError = useCallback(error => {
    toaster.push(
      <Notification type="error" header="Error">{error?.error || "Неизвестная ошибка"}</Notification>
    );
  }, []);

  useEffect(() => {
    Api.spotWalletBalances()
      .then(data => data.filter(a => +a.btcValuation > 0))
      .then(data => setSpotWalletBalances(data))
      .catch(handleError);

    Api.spotDepositHistory().then(data => setSpotDepositHistory(data)).catch(handleError);
    Api.spotWithdrawHistory().then(data => setSpotWithdrawHistory(data)).catch(handleError);
    Api.spotOpenOrders().then(data => setSpotOpenOrders(data)).catch(handleError);
    Api.futuresAccountBalances().then(data => setFuturesAccountBalances(data)).catch(handleError);
    Api.futuresOpenOrders().then(data => setFuturesOpenOrders(data)).catch(handleError);
    Api.futuresPositions().then(data => setFuturesPositions(data)).catch(handleError);
  }, [handleError]);


  return (
    <div>
      <h2>Spot</h2>
      <SpotWalletBalancesTable walletBalances={spotWalletBalances} /><br />
      <SpotOpenOrders openOrders={spotOpenOrders} /><br />
      <SpotDepositHistory depositHistory={spotDepositHistory} /><br />
      <SpotWithdrawHistory withdrawHistory={spotWithdrawHistory} /><br />

      <hr />

      <h2>Futures</h2>
      <FuturesAccountBalances accountBalances={futuresAccountBalances} /><br />
      <FuturesOpenOrders openOrders={futuresOpenOrders} /><br />
      <FuturesPositions positions={futuresPositions} /><br />
    </div>
  );
}

function SpotWalletBalancesTable({ walletBalances }) {
  const { Column, HeaderCell, Cell } = Table;

  return (
    <>
      <h4>Wallet Balances</h4>

      <Table data={walletBalances} autoHeight>
        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Asset</HeaderCell>
          <Cell dataKey="asset" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Free</HeaderCell>
          <Cell dataKey="free" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Locked</HeaderCell>
          <Cell dataKey="locked" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>BTC Valuation</HeaderCell>
          <Cell dataKey="btcValuation" />
        </Column>
      </Table>
    </>
  );
}

function SpotOpenOrders({ openOrders }) {
  const { Column, HeaderCell, Cell } = Table;

  return (
    <>
      <h4>Open Orders</h4>

      <Table data={openOrders} autoHeight>
        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Symbol</HeaderCell>
          <Cell dataKey="symbol" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Side</HeaderCell>
          <Cell dataKey="side" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Quantity</HeaderCell>
          <Cell dataKey="origQty" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Type</HeaderCell>
          <Cell dataKey="type" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Price</HeaderCell>
          <Cell dataKey="price" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Status</HeaderCell>
          <Cell dataKey="status" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Time</HeaderCell>
          <Cell>
            {row => new Date(row.time).toLocaleString('ua-UA')}
          </Cell>
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Client</HeaderCell>
          <Cell>
            {row => getOrderClient(row.clientOrderId)}
          </Cell>
        </Column>
      </Table>
    </>
  );
}

function SpotDepositHistory({ depositHistory }) {
  const { Column, HeaderCell, Cell } = Table;

  return (
    <>
      <h4>Deposit History</h4>

      <Table data={depositHistory} autoHeight>
        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Coin</HeaderCell>
          <Cell dataKey="coin" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Network</HeaderCell>
          <Cell dataKey="network" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Quantity</HeaderCell>
          <Cell dataKey="amount" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Date</HeaderCell>
          <Cell>
            {row => new Date(row.insertTime).toLocaleString('ua-UA')}
          </Cell>
        </Column>
      </Table>
    </>
  );
}

function SpotWithdrawHistory({ withdrawHistory }) {
  const { Column, HeaderCell, Cell } = Table;

  return (
    <>
      <h4>Withdraw History</h4>

      <Table data={withdrawHistory} autoHeight>
        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Coin</HeaderCell>
          <Cell dataKey="coin" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Network</HeaderCell>
          <Cell dataKey="network" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Quantity</HeaderCell>
          <Cell dataKey="amount" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Date</HeaderCell>
          <Cell>
            {row => new Date(row.completeTime).toLocaleString('ua-UA')}
          </Cell>
        </Column>
      </Table>
    </>
  );
}

function FuturesAccountBalances({ accountBalances }) {
  const { Column, HeaderCell, Cell } = Table;

  return (
    <>
      <h4>Account Balances</h4>

      <Table data={accountBalances} autoHeight>
        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Asset</HeaderCell>
          <Cell dataKey="asset" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Balance</HeaderCell>
          <Cell dataKey="balance" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Available Balance</HeaderCell>
          <Cell dataKey="availableBalance" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>PnL</HeaderCell>
          <Cell dataKey="crossUnPnl" />
        </Column>
      </Table>
    </>
  );
}

function FuturesOpenOrders({ openOrders }) {
  const { Column, HeaderCell, Cell } = Table;

  return (
    <>
      <h4>Open Orders</h4>

      <Table data={openOrders} autoHeight>
        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Symbol</HeaderCell>
          <Cell dataKey="symbol" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Side</HeaderCell>
          <Cell dataKey="side" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Quantity</HeaderCell>
          <Cell dataKey="origQty" />
        </Column>

        <Column minWidth={200} flexGrow={1}>
          <HeaderCell>Type</HeaderCell>
          <Cell dataKey="type" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Price</HeaderCell>
          <Cell>
            {row => +row.price > 0 ? row.price : row.stopPrice}
          </Cell>
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Reduce Only</HeaderCell>
          <Cell>
            {row => row.reduceOnly.toString()}
          </Cell>
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Close Position</HeaderCell>
          <Cell>
            {row => row.closePosition.toString()}
          </Cell>
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Status</HeaderCell>
          <Cell dataKey="status" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Time</HeaderCell>
          <Cell>
            {row => new Date(row.time).toLocaleString('ua-UA')}
          </Cell>
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Client</HeaderCell>
          <Cell>
            {row => getOrderClient(row.clientOrderId)}
          </Cell>
        </Column>
      </Table>
    </>
  );
}

function FuturesPositions({ positions }) {
  const { Column, HeaderCell, Cell } = Table;

  return (
    <>
      <h4>Positions</h4>

      <Table data={positions} autoHeight>
        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Symbol</HeaderCell>
          <Cell dataKey="symbol" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Side</HeaderCell>
          <Cell>
            {row => +row.positionAmt > 0 ? 'LONG' : 'SHORT'}
          </Cell>
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>PnL</HeaderCell>
          <Cell>
            {row => Number(row.unrealizedProfit).toFixed(4) + '$'}
          </Cell>
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Entry Price</HeaderCell>
          <Cell>
            {row => Number(row.entryPrice).toFixed(4) + '$'}
          </Cell>
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Position Size</HeaderCell>
          <Cell>
            {row => Math.abs(+row.positionAmt)}
          </Cell>
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Position Margin</HeaderCell>
          <Cell dataKey="positionInitialMargin" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Leverage</HeaderCell>
          <Cell dataKey="leverage" />
        </Column>

        <Column minWidth={150} flexGrow={1}>
          <HeaderCell>Isolated</HeaderCell>
          <Cell>
            {row => row.isolated.toString()}
          </Cell>
        </Column>
      </Table>
    </>
  );
}

function getOrderClient(clientOrderId) {
  const [, clientName] = clientOrderId.match(/^(\w+)_/) || [];
  return clientName || 'api';
}
