import { Link } from 'react-router-dom'
import { Container } from 'rsuite';
import {
  Col,
  Divider,
  FlexboxGrid,
  Panel,
} from 'rsuite';

import Register from '../../components/Auth/Register';

export default function RegisterView() {
  return (
    <Container>
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item as={Col} colspan={20} style={{maxWidth: 400}}>
          <Panel header={<h3>Register</h3>} bordered style={{ marginTop: 100 }}>
            <Register />

            <Divider />

            Already have an account? <strong><Link to="/login">Login</Link></strong>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Container>
  );
}
