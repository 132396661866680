import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FlexboxGrid, Col, useToaster, Notification } from 'rsuite';
import moment from 'moment';
import OrderForm from '../../components/ScheduledTrade/TradeForm';
import Api from '../../lib/api';

const LOCAL_STORAGE_KEY = 'scheduledTradingFormValues';

export default function ScheduledTrade() {
  const navigate = useNavigate();
  const toaster = useToaster();

  const onSubmit = (formData) => {
    const currentTime = new Date();

    const takeProfitOrder = {
      placementDate: moment(formData.takeProfitOrderPlacementDate || currentTime).format("yyyy-MM-DD HH:mm:ss"),
      value: +formData.takeProfitOrderValue,
      removalDate: moment(formData.takeProfitOrderRemovalDate || currentTime).format("yyyy-MM-DD HH:mm:ss"),
    };

    const stopLossOrder = {
      placementDate: moment(formData.stopLossOrderPlacementDate || currentTime).format("yyyy-MM-DD HH:mm:ss"),
      value: +formData.stopLossOrderValue,
      removalDate: moment(formData.stopLossOrderRemovalDate || currentTime).format("yyyy-MM-DD HH:mm:ss"),
    };

    return Api.createDelayedOrder({
      symbol: formData.symbol,
      quantity: +formData.quantity,
      leverage: +formData.leverage,

      startTradingDate: moment(formData.startDate || currentTime).format("yyyy-MM-DD HH:mm:ss"),
      finishPlaceOrderAttemptsDate: moment(formData.finishDate || currentTime).format("yyyy-MM-DD HH:mm:ss"),

      maxDeviation: +formData.maxDeviation,
      maxSpread: +formData.maxSpread,

      takeProfitOrderEnabled: formData.takeProfitOrderEnabled,
      takeProfitOrder: formData.takeProfitOrderEnabled ? takeProfitOrder : {},

      stopLossOrderEnabled: formData.stopLossOrderEnabled,
      stopLossOrder: formData.stopLossOrderEnabled ? stopLossOrder : {},

      stops: formData.stops.map(stop => ({
        id: stop.id,
        expectedPricePercentage: +stop.expectedPricePercentage,
        stopPricePercentage: +stop.stopPricePercentage,
      })),

      repeatFrequency: +formData.repeatFrequency,

      tradeDirection: formData.tradeDirection,
    })
    .then(() => {
      toaster.push(<Notification type="success" header="Создано" />);
      navigate('/');
    })
    .catch(error => {
      toaster.push(<Notification type="error" header="Ошибка">{error?.error || error?.message || "Неизвестная ошибка"}</Notification>);
    });
  }

  const handleFormSave = useCallback((formValue) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(formValue));
  }, []);

  const handleFormRestore = useCallback(() => {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  }, [])

  return (
    <FlexboxGrid justify="center">
      <FlexboxGrid.Item as={Col} colspan={24} md={20} style={{ maxWidth: 800 }}>
        <OrderForm onSubmit={onSubmit} onFormSave={handleFormSave} onFormRestore={handleFormRestore} />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
