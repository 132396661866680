import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Header as HeaderBase, Navbar, Nav } from 'rsuite';
import styled from 'styled-components';
import useSession from '../../hooks/session';
import { device } from '../../utils/screenSizeHelpers';

export default function Header() {
  const { username } = useSession();
  const { pathname } = useLocation();

  const NavItems = () => (
    <>
      <Nav.Item eventKey="/" to="/" as={Link}>Home</Nav.Item>
      <Nav.Item eventKey="/schedule" as={Link} to="/schedule">Schedule Trading</Nav.Item>
      <Nav.Item eventKey="/conditional" to="/conditional" as={Link}>Conditional Trading</Nav.Item>
      <Nav.Item eventKey="/stablecoin" to="/stablecoin" as={Link}>StableCoin Bot</Nav.Item>
      <Nav.Item eventKey="/account_details" to="/account_details" as={Link}>Account Details</Nav.Item>
    </>
  )

  return (
    <HeaderBase>
      <Styles>
        <Navbar>

          <Nav className="nav-mobile" activeKey={pathname}>
            <Nav.Menu title="Menu">
              <NavItems />
            </Nav.Menu>
          </Nav>

          <Nav className="nav-desktop" activeKey={pathname}>
            <NavItems />
          </Nav>

          <Nav pullRight>
            <Nav.Menu title={username}>
              <Nav.Item as={Link} to="/logout">Logout</Nav.Item>
            </Nav.Menu>
          </Nav>

        </Navbar>
      </Styles>
    </HeaderBase>
  );
};

const Styles = styled.div`
  .nav-mobile {
    display: block;
  }

  .nav-desktop {
    display: none;
  }

  @media ${device.tablet} {
    .nav-desktop {
      display: block;
    }

    .nav-mobile {
      display: none;
    }
  }
`;
