import styled from 'styled-components';

const rsuiteOverrides = `
  .rs-form-vertical .rs-form-help-text-tooltip {
    margin-top: 0px;
    margin-left: 5px;
  }
`;

export default styled.div`
  ${rsuiteOverrides}
`;
