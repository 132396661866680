import { Outlet } from 'react-router-dom';
import { Container, Content, Panel } from 'rsuite';
import Header from './Header';

export default function Root() {
  return (
    <Container>
      <Header />
      <Content>
        <Panel>
          <Outlet />
        </Panel>
      </Content>
    </Container>
  );
}


