import { Panel, Stack, Button, Tag } from 'rsuite';
import { Link } from 'react-router-dom';
import PlusIcon from '@rsuite/icons/Plus';
import CloseIcon from '@rsuite/icons/Close';

const Card = ({ id, symbol, startTime, tradeDirection, quantity, onDelete }) => (
  <Panel
    header={
      <Stack justifyContent="space-between">
        <span>{symbol}</span>
        <Button color="red" appearance="subtle" onClick={() => onDelete(id)}><CloseIcon /></Button>
      </Stack>
    }
    bordered
    shaded
  >
    <Stack justifyContent="space-between">
      <Tag color={tradeDirection === "BUY" ? "green" : "red"}>{tradeDirection}</Tag>
      <span>{startTime}</span>
      <span>{quantity} USD</span>
    </Stack>
  </Panel>
);

const NewTradeCard = () => (
  <Panel bordered shaded>
    <Link
      to="new"
      style={{
        display: 'block',
        fontSize: 16,
        textAlign: 'center',
        textDecoration: 'none',
      }}
    >
      <span>Новый трейд</span> <PlusIcon />
    </Link>
  </Panel>
);
export default function ListTrades({ orders, onDelete }) {
  return (
    <Stack direction='column' spacing={15}>
      <Stack.Item style={{ width: '100%' }}>
        <NewTradeCard />
      </Stack.Item>

      {orders.map(item => (
        <Stack.Item key={item.id} style={{ width: '100%' }}>
          <Card {...item} onDelete={onDelete} />
        </Stack.Item>
      ))}
    </Stack>
  );
}
