import { useLayoutEffect, useState } from 'react';

export default function useTheme() {
  const [theme, setTheme] = useState('light');

  const themeChangedListener = (event) => {
    setTheme(event.matches ? 'dark' : 'light');
  }

  useLayoutEffect(() => {
    if (!window.matchMedia) return;

    const mediaQuery = '(prefers-color-scheme: dark)';

    themeChangedListener(window.matchMedia(mediaQuery));

    window.matchMedia(mediaQuery).addEventListener('change', themeChangedListener);

    return () => window.matchMedia(mediaQuery).removeEventListener('change', themeChangedListener);
  }, [])

  return theme;
}
